import * as React from 'react'
import { Slot } from '@radix-ui/react-slot'
import { cva, type VariantProps } from 'class-variance-authority'

import { cn } from '@admin/business/misc.tsx'
import { clsCat } from '#app/styles/styles.utils'

const buttonVariants = cva(
  clsCat({
    base: 'text-midnight inline-flex items-center justify-center whitespace-nowrap text-sm font-medium ring-offset-background transition-colors',
    hover: 'hover:text-black',
    focus:
      'focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2',
    border: 'border rounded-md',
    disabled: 'disabled:pointer-events-none disabled:opacity-50',
  }),
  {
    variants: {
      intent: {
        primary: clsCat({
          base: 'bg-midnight',
          border: 'border-midnight rounded-[90px]',
          hover: 'hover:bg-slate-500',
          focus: 'focus:bg-slate-500',
        }),
        secondary: clsCat({
          base: 'bg-bone',
          border: 'border-bone rounded-[90px]',
          hover: 'hover:bg-accent hover:text-accent-foreground',
          focus: 'focus:bg-accent',
        }),
        destructive: clsCat({
          base: 'bg-destructive text-destructive-foreground',
          border: 'border-midnight rounded-[90px]',
          hover: 'hover:bg-destructive/90',
          focus: 'focus:bg-slate-500',
        }),
      },
      variant: {
        filled: clsCat({
          base: 'text-bone',
          hover: 'hover:bg-midnight/80 hover:text-bone',
        }),
        outline: clsCat({
          base: 'bg-bone',
          border: 'border-2 border-midnight',
          hover: 'hover:bg-accent hover:text-accent-foreground',
        }),
        ghost: clsCat({
          base: 'bg-opacity-10 backdrop-blur',
          text: 'text-bone',
          border: 'border-2',
        }),
        ghostDark: clsCat({
          base: 'bg-dark/60 bg-opacity-10 backdrop-blur-xl',
          text: 'text-bone',
          border: 'border-0',
          hover: 'hover:bg-accent hover:text-accent-foreground',
        }),
        link: clsCat({
          base: 'underline-offset-4 hover:underline',
          text: 'text-primary-foreground',
        }),
        default: clsCat({
          text: 'text-midnight',
        }),
      },
      size: {
        default: 'h-10 px-4 py-2',
        wide: 'px-24 py-5',
        sm: 'h-9 rounded-md px-3',
        lg: 'h-11 rounded-md px-8',
        pill: 'px-12 py-3 leading-3',
        icon: 'h-10 w-10',
      },
    },
    defaultVariants: {
      intent: 'primary',
      variant: 'default',
      size: 'default',
    },
  },
)

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, intent, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button'
    return (
      <Comp
        className={cn(buttonVariants({ intent, variant, size, className }))}
        ref={ref}
        {...props}
      />
    )
  },
)
Button.displayName = 'Button'

export { Button, buttonVariants }
